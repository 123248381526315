/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location }) => {
  // Scroll to top on route change (this also fix a Nav bug)
  window.scrollTo(0, 0)

  // Instagram embeded posts
  if (document.querySelector(".instagram-media") !== null) {
    if (
      typeof gatsbyLoadInstagram !== `undefined` &&
      typeof window.gatsbyLoadInstagram === `function`
    ) {
      window.gatsbyLoadInstagram()
      if (window.instgrm) {
        window.instgrm.Embeds.process()
      }
    }
  }

  // Send page view event to GTM
  // console.log('send page view');
  window.dataLayer.push({event: 'react_page_vue'});

}

exports.onInitialClientRender = () => {
  var isIE11OrOlder =
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > -1
  if (isIE11OrOlder) {
    window.scrollTo(0, 0)
    document.getElementById("___ie").style.display = "flex"
    document
      .getElementsByTagName("html")
      .setAttribute("style", "-ms-scroll-limit: 0 0 0 0; overflow: hidden;")
  } else {
    setTimeout(() => {
      document.getElementById("___loader").style.display = "none"
    }, 500)
  }
}
