// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-adventure-js": () => import("./../../../src/templates/page-Adventure.js" /* webpackChunkName: "component---src-templates-page-adventure-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-Contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page-Default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-faq-js": () => import("./../../../src/templates/page-FAQ.js" /* webpackChunkName: "component---src-templates-page-faq-js" */),
  "component---src-templates-page-homepage-js": () => import("./../../../src/templates/page-Homepage.js" /* webpackChunkName: "component---src-templates-page-homepage-js" */),
  "component---src-templates-page-join-js": () => import("./../../../src/templates/page-Join.js" /* webpackChunkName: "component---src-templates-page-join-js" */),
  "component---src-templates-page-stores-js": () => import("./../../../src/templates/page-Stores.js" /* webpackChunkName: "component---src-templates-page-stores-js" */),
  "component---src-templates-shop-archive-js": () => import("./../../../src/templates/shop-archive.js" /* webpackChunkName: "component---src-templates-shop-archive-js" */),
  "component---src-templates-shop-category-js": () => import("./../../../src/templates/shop-category.js" /* webpackChunkName: "component---src-templates-shop-category-js" */),
  "component---src-templates-shop-product-js": () => import("./../../../src/templates/shop-product.js" /* webpackChunkName: "component---src-templates-shop-product-js" */)
}

