module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Funky Veggie","short_name":"Funky Veggie","description":"La Boule, Cœur de Boule, L'apéro Boules et OUF!, des produits gourmands, 100% naturels et funky ! Vegan et sans gluten, et 'sans rien de bizarre à l'intérieur' !","lang":"fr","display":"standalone","icon":"src/images/funky-favicon-trans.png","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a4eb83205bebaf2df12412e14d48df7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.funkyveggie.fr"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
